import * as React from 'react';
// import { StaticImage } from "gatsby-plugin-image"
import Layout from '../components/layout';
import Separator from '../components/separator';
import { IoIosArrowDown } from 'react-icons/io';
import Seo from '../components/seo';
import Messenger from '../images/fb-messenger-logo.svg';
import WhatsApp from '../images/whatsapp.svg';

const StepsPage = () => {
  return (
    <Layout>
      <Seo
        title="Шаги"
        description="Вы можете обрести настоящий мир уже сегодня благодаря отношениям с Иисусом Христом. Начните свой путь, состоящий из четырех шагов прямо сейчас!"
        pathname='shagi'
      />
      <div className="content-container">
        <div className="step-wrapper">
          <div className="triangle"></div>
          <h2 className="subtitle is-3">Первый Шаг</h2>
          <h3 className="is-size-5">Цель Бога: мир и жизнь</h3>
          <h3 className="is-size-4">
            Бог любит вас и хочет, чтобы вы испытали душевный покой и вечную
            жизнь.
          </h3>
          <Separator />
          <p>
            <strong>Библия говорит:</strong>
          </p>
          <p>
            «Мы имеем мир с Богом через Господа нашего Иисуса Христа». К
            Римлянам 5:1
          </p>
          <p>
            «Ибо так возлюбил Бог мир, что отдал Сына Своего Единородного, дабы
            всякий верующий в Него не погиб, но имел жизнь вечную». От Иоанна
            3:16
          </p>
          <p>
            «Я [Иисус] пришел, чтобы дать жизнь, и притом в избытке». От Иоанна
            10:10
          </p>
          <p>
            Что же удерживает нас от той жизни, которую Бог задумал для нас?
          </p>
          <div className="arrow">
            <IoIosArrowDown />
          </div>
        </div>
        <div
          className="step-wrapper"
          data-sal="slide-up"
          data-sal-duration="700"
          data-sal-easing="ease"
        >
          <div className="triangle"></div>
          <h2 className="subtitle is-3">Второй Шаг</h2>
          <h3 className="is-size-5">Проблема: наше разобщение с Богом</h3>
          <h3 className="is-size-4">
            Бог создал нас по Своему образу и подобию. Он дал нам волю и свободу
            выбора; мы выбрали неповиновение Божьей воле и собственный путь,
            который называется грехом. Именно грех и отделяет нас от Бога.
          </h3>
          <Separator />
          <p>
            <strong>Библия говорит:</strong>
          </p>
          <p>
            «Ведь все согрешили, и все лишились Божьей славы». К Римлянам 3:23
          </p>
          <p>
            «Ведь возмездие за грех – смерть, а дар Божий – вечная жизнь в
            единении с Иисусом Христом, нашим Господом». К Римлянам 6:23
          </p>
          <p>
            Именно наш выбор, идти путем греха, и отделяет нас от Бога. Люди
            испробовали множество способов, чтобы преодолеть этот разрыв между
            собой и Богом.
          </p>
          <p>
            <strong>Библия говорит:</strong>
          </p>
          <p>
            «Есть пути, которые кажутся человеку прямыми; но конец их - путь к
            смерти». Притчи 14:12
          </p>
          <p>
            «Но ваши беззакония отделили вас от Бога; грехи ваши скрыли от вас
            лицо Его, так что Он не слышит». Исаия 59:2
          </p>
          <p>Ни какой мост не приведёт к Богу… за исключением одного.</p>
          <div className="arrow">
            <IoIosArrowDown />
          </div>
        </div>
        <div
          className="step-wrapper"
          data-sal="slide-up"
          data-sal-duration="700"
          data-sal-easing="ease"
        >
          <div className="triangle"></div>
          <h2 className="subtitle is-3">Третий Шаг</h2>
          <h3 className="is-size-5">Мост, предоставленный Богом: Крест</h3>
          <h3 className="is-size-4">
            Иисус Христос умер на кресте и воскрес из гроба. Сделав это, Он
            расплатился за наш грех и преодолел пропасть между нами и Богом.
          </h3>
          <Separator />
          <p>
            <strong>Библия говорит:</strong>
          </p>
          <p>
            «Ибо един Бог, един и посредник между Богом и человеками, человек
            Христос Иисус». 1-е к Тимофею 2:5
          </p>
          <p>
            «Ведь и Христос пострадал за грехи один раз, праведный за
            неправедных, чтобы привести вас к Богу. Его тело было умерщвлено, но
            Он был оживлен Духом». 1-е Петра 3:18
          </p>
          <p>
            Бог предоставил каждому из нас единственный путь к Нему. И вы должны
            сделать выбор.
          </p>
          <div className="arrow">
            <IoIosArrowDown />
          </div>
        </div>
        <div
          className="step-wrapper"
          data-sal="slide-up"
          data-sal-duration="700"
          data-sal-easing="ease"
        >
          <div className="triangle"></div>
          <h2 className="subtitle is-3">Четвертый Шаг</h2>
          <h3 className="is-size-5">Наш ответ: примите Христа</h3>
          <h3 className="is-size-4">
            Мы должны довериться и принять Иисуса Христа как Господа и
            Спасителя.
          </h3>
          <Separator />
          <p>
            <strong>Библия говорит:</strong>
          </p>
          <p>
            «Но всем тем, кто Его принял и кто поверил в Его имя, Он дал власть
            стать детьми Божьими». От Иоанна 1:12
          </p>
          <p>
            «Ибо если устами твоими будешь исповедовать Иисуса Господом и
            сердцем твоим веровать, что Бог воскресил Его из мертвых, то
            спасешься». К Римлянам 10:9
          </p>
          <p>На чьей вы стороне? На стороне со Христом или без Него?</p>
          <div className="arrow">
            <IoIosArrowDown />
          </div>
        </div>
        <section className="prayer-wrapper">
          <p>
            <strong>
              Вы можете принять Христа в свою жизнь следующим образом:
            </strong>
          </p>
          <ul>
            <li>Признайте свою потребность. (Я грешник)</li>
            <li>Будьте готовы отвернуться от своих грехов. (Покайтесь)</li>
            <li>
              Поверьте, что Иисус Христос умер за вас на кресте и воскрес из
              гроба. (Получите спасение через веру)
            </li>
            <li>
              Через молитву пригласите Иисуса Христа и отдайте свою жизнь Eму в
              управление через Святого Духа. (Примите его как Господа и
              Спасителя)
            </li>
          </ul>
          <p>
            Мы предлагаем вам нижеприведенную молитву, чтобы принять Христа как
            своего Спасителя:
          </p>
          <p id="prayer">
            “Дорогой Господь, Я знаю, что я грешник (грешница), и я прошу у Тебя
            прощения. Я верю, что Иисус Христос – Твой Сын. Я верю, что Он умер
            за мои грехи и что Ты воскресил Его к жизни. С сегодняшнего дня я
            хочу доверять Ему как своему Спасителю и следовать за Ним как за
            Господом. Направляй мою жизнь и помоги мне исполнить Твою волю. Я
            молюсь об этом во имя Иисуса Христа. Аминь.”
          </p>
        </section>
        <div class="arrow-wrapper">
          <div class="arrow">
            <IoIosArrowDown />
          </div>
        </div>
        <div className="cta-wrapper">
          <h3 className="is-size-5 mb-4 has-text-centered">
            Мы с радостью поговорим с вами, если у вас есть вопросы или если вы
            хотите узнать больше, перед тем как делать следующий шаг.
          </h3>
          <div>
            {/* <a
              href="https://wa.me/37282720748/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={WhatsApp} alt="WhatsApp" />
              <p>WhatsApp</p>
            </a> */}

            <a
              href="http://m.me/mir.s.bogom.bgea"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={Messenger} alt="Facebook Messenger" />
              <p>Messenger</p>
            </a>
          </div>
          {/* <a href={outreach ? "/?outreach=" + outreach : "/"} className="button mb-4">No, but I have a question</a>
                    <a href={outreach ? "/?outreach=" + outreach : "/"} className="button mb-2">Yes, I prayed the prayer</a>*/}
        </div>
      </div>
    </Layout>
  );
};

export default StepsPage;
